import validate from "/root/injective/mito-ui/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/root/injective/mito-ui/middleware/maintenance.global.ts";
import route_45change_45global from "/root/injective/mito-ui/middleware/route-change.global.ts";
import trailling_45slash_45global from "/root/injective/mito-ui/middleware/trailling-slash.global.ts";
import manifest_45route_45rule from "/root/injective/mito-ui/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  route_45change_45global,
  trailling_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-dev-mode": () => import("/root/injective/mito-ui/middleware/is-dev-mode.ts"),
  "is-master-contract-owner": () => import("/root/injective/mito-ui/middleware/is-master-contract-owner.ts"),
  "is-owner": () => import("/root/injective/mito-ui/middleware/is-owner.ts")
}